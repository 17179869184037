import styles from './PaymentRedirectPage.module.css'
import sprite from '../icons.svg'
import { Link } from "react-router-dom";

export default function PaymentRedirectPage() {
    return (
      <section className={styles.prpSection}>
        <div className="container">
          <svg className={styles.iconRedWomenAggreSVG} width={320} height={320}>
            <use xlinkHref={`${sprite}#icon-redWomenIMG`}></use>
          </svg>
          <h2 className={styles.titlePaymentPage}>
            Дякуємо за покупку та з нетерпінням чекаємо зустрічі на конференції.
          </h2>
          <Link to="/conference">
            <button className={styles.backToConferencePageBtn}>
              До конференції
            </button>
          </Link>
        </div>
      </section>
    );
}